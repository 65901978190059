.time-range-tab {
  cursor: pointer;
  padding: 0.6rem 1.2rem;
  border-radius: 0.8rem;
  color: #fff;
  font-weight: 700;
  &:hover {
    opacity: 1;
  }
  &.active {
    opacity: 1;
    background: #926ce3;
    font-weight: bold;
  }
}
