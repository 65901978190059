.orderbook-table {
  & table {
    thead tr th {
      font-size: 12px;
      font-weight: bold;
      padding-bottom: 1rem;
    }
    tbody tr {
      cursor: pointer;
    }
    tbody tr td {
      font-size: 12px;
      font-weight: bold;
      padding-bottom: 0.8rem;
      width: 30%;
    }
  }
}
