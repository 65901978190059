@keyframes skelton-animation {
  0% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.skeleton {
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.163) 0%,
    rgba(255, 255, 255, 0.163) 40%,
    rgba(63, 63, 63, 0.329) 50%,
    rgba(63, 63, 63, 0.329) 55%,
    rgba(255, 255, 255, 0.163) 65%,
    rgba(255, 255, 255, 0.163) 100%
  );
  background-size: 400%;
  animation: skelton-animation 1.5s 0.5s ease-in-out infinite;
  border-radius: 5px;
  & > * {
    visibility: hidden;
  }
}
