@keyframes tooltip-animation {
  from {
    opacity: 0;
    top: 0%;
  }
  to {
    opacity: 1;
    top: 2rem;
  }
}

table {
  border-collapse: collapse !important;
}

.table-wrapper {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  &-icon {
    transition: all 0.1s ease-out;
  }

  .extra-header {
    color: #926ce3;
    // font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  &-tooltip {
    .tooltip {
      text-align: center;
      min-width: 17rem;
      top: 2rem;
      bottom: unset;
      left: 0;
      animation: tooltip-animation 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    &.header {
      .tooltip {
        width: 20rem;
      }
    }
  }

  .table-header-hint {
    // position: absolute;
    left: 10px;
    cursor: pointer;
    vertical-align: bottom;
  }

  & table {
    border-spacing: 2px 3px;
    width: 100%;
    display: table;

    tr {
      &.expanded {
        td {
          background-color: var(--color-black-2);
        }
      }
    }

    tbody tr {
      // border-bottom: 2px solid rgba(2, 0, 3, 0.5);

      &.expanded {
        border-bottom: none;
      }

      &:hover .table-wrapper-icon {
        transform: scale(140%);
        transition: all 0.1s ease-in;
      }
    }

    tbody tr td {
      // background-color: #181422;
    }
    thead tr th {
      // padding: 0.85rem 2rem;
      white-space: pre;
      // font-size: 1.4rem;
      position: relative;
    }

    & td:first-child {
      border-radius: 4px 0 0 4px;
    }
    td:last-child,
    th:last-child {
      border-radius: 0 4px 4px 0;
    }
    & tr > th {
      text-align: left;
      color: #858585;
      font-weight: 400;
      // font-size: 12px;
      // opacity: 0.6;
      // padding: 1.5rem 2rem;
      white-space: pre;
    }

    & tbody > tr > td {
      color: #fff;
      font-weight: 400;
      // font-size: 16px;
      // padding: 1.3rem 2rem;
      text-align: left;
    }

    td:first-child,
    th:first-child {
      border-radius: 0;
    }
    td:last-child,
    th:last-child {
      border-radius: 0;
    }
  }
  // &::-webkit-scrollbar {
  //   width: 100%;
  //   height: 5px;
  // }

  // /* Track */
  // &::-webkit-scrollbar-track {
  // }

  // /* Handle */
  // &::-webkit-scrollbar-thumb {
  //   background: #ddd;
  //   border-radius: 7px;
  // }

  // /* Handle on hover */
  // &::-webkit-scrollbar-thumb:hover {
  // }

  td.expanded {
    padding: 0 !important;
    background-color: var(--color-black-2) !important;
  }
}
