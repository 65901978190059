.duf-sidebar-link {
  .vdao-star {
    fill: #928f9c !important;
  }

  .vdao-svg:not(.active) .vdao-star {
    fill: #928f9c !important;
  }

  &:hover {
    &.wide:not(.active) .vdao-svg {
      fill: none !important;
    }

    &:not(.active) .vdao-svg {
      fill: none !important;
    }

    .vdao-svg circle {
      stroke: white;
    }
    .vdao-svg {
      fill: none !important;
    }
    .vdao-svg .vdao-star {
      fill: white !important;
    }

    &.active.wide .vdao-svg .vdao-star {
      fill: #926ce3 !important;
    }

    &.active:not(.wide) .vdao-svg .vdao-star {
      fill: #926ce3 !important;
    }

    &:not(.active).wide .vdao-svg .vdao-star {
      fill: white !important;
    }
  }

  &.active .vdao-svg circle {
    stroke: #926ce3;
  }

  &.active:not(.wide) .vdao-svg {
    fill: none !important;
  }
  &.active.wide .vdao-svg {
    fill: none !important;
  }
}
