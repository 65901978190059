.duf-header {
  background: #1e1628 !important;
  .duf-contextMenu {
    display: none;
  }

  .hord-header-buttons {
    .openSea {
      margin-right: 0;
    }
  }

  .duf-header-logo-text {
    color: #fff;
    font-weight: 500;
    font-size: 1rem;
    background: #8d6752;
    border-radius: 3px;
    padding: 3px;
    text-transform: uppercase;
    margin-left: 7px;
  }

  @media screen and (max-width: 1000px) {
    .hord-header-buttons {
      .openSea {
        width: 290px;
        margin: auto;
        text-align: center;
      }
    }
  }
}

aside.duf-aside {
  z-index: 1025 !important;
}

.pointer {
  cursor: pointer;
}

iframe {
  display: none;
}

header.vdao-header {
  position: fixed;
  display: none !important;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 10;
  background: #1e1628;

  .header-token-box {
    min-height: 40px;
  }
}
.sidebar-mobile-logo {
  position: fixed;
  top: 10px;
  left: 15px;
}

.aside-top-content {
  order: 6; // TODO: increase this order to links.length + 1
}
