.pairs-filter {
  min-width: 20rem;
  background: #525562;

  .pair-tab {
    padding: 0.6rem 0;
    cursor: pointer;
  }

  .pair-tab-inactive {
    .pair-tab-text {
      transition: color 0.2s ease;
    }
  }

  .pair-tab-active {
    background: #926ce3;
  }
}
