.network-type-wrapper {
  background-color: #616472;
  min-height: 4rem;

  &.--hord {
    transition: all ease-in-out 0.1s;
    &:hover {
      background-color: #926ce3;
    }
  }
}
