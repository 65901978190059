.percent-tabs {
  border-bottom: 1px solid #6c6f7c;
  padding-bottom: 2.8rem;
  margin-bottom: 2.8rem;

  .percent-tab {
    transition: all 0.1s ease-in-out;
    &:hover {
      border: 1px solid #926ce3;
    }

    &-active {
      background: #926ce3;
      border: 1px solid #926ce3;
    }
  }
}
