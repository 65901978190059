.recent-trades-table {
  & table {
    thead tr th {
      font-size: 1.2rem;
      padding-bottom: 1rem;
    }
    tbody tr td {
      font-size: 1.4rem;
      font-weight: bold;
      padding-bottom: 0.8rem;
    }
  }
}
