.wave-line-animation {
  max-width: 100%;
  width: 100%;
  animation: wavy-ainmation 7s ease-in infinite;
}

@keyframes wavy-ainmation {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-2000px);
  }
}
